import './App.css';
import Borad_1 from "./assets2/Boardgame/Board/Board_A_v2.png";
import Borad_2 from "./assets2/Boardgame/Board/Board_B_v2.png";
import Character_1 from "./assets2/Boardgame/Characters/1 Character.png";
import video from "./assets2/React App - Google Chrome 2024-08-09 17-33-34.mp4"
function App() {
  return (
    <>
      <div className='board_container'>
        <div className='boards'>
          <img src={Borad_1}></img>
          <img src={Borad_2}></img>
        </div>
        <div className='character_container'>
          <img src={Character_1}></img>
        </div>
      </div>

    </>
  );
}

export default App;
